import { Center, Spinner } from '@chakra-ui/react';
import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useStore } from '../store';

const useOAuthHandler = () => {
  const store = useStore('user');
  const history = useHistory();

  useEffect(() => {
    const location = new URLSearchParams(history.location.search);

    if (location.has('code')) {
      const token = location.get('code');

      if (token && token.length === 128) {
        store.login(token)
          .then(() => history.push('/'))
          .catch(() => history.push('/login', { error: 'Login failed, please try again' }));
      } else {
        history.push('/login', { error: 'Token is wrong' });
      }
    } else {
      history.push('/login');
    }
  }, [store, history]);
};

export const OAuthHandler: FC = () => {
  useOAuthHandler();

  return (
    <Center h="full"><Spinner size="xl" /></Center>
  );
};
