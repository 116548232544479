import { observer } from 'mobx-react-lite';
import { Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Camera } from '../icons/camera.svg';
import { ReactComponent as CheckMarks } from '../icons/check-marks.svg';
import { ReactComponent as Glasses } from '../icons/glasses.svg';
import { ReactComponent as Home } from '../icons/home.svg';
import { Route, RouteBox } from './RouteBox';
import { useStore } from '../store';

const reservedRoutes: Array<string> = ['/profile', '/quiz/:id'];

export const BottomBar: FC = observer(() => {
  const { pathname } = useLocation();
  const store = useStore('user');
  const quizList = useStore('quizList');
  const { t } = useTranslation();

  const shouldNotShowBottomBar = (): boolean => (
    reservedRoutes.some((route) => {
      const match = matchPath(pathname, {
        path: route,
        exact: true,
        strict: false,
      });
      return match !== null;
    })
  );

  const links: Route[] = [
    { target: '/', icon: Home, name: t('menu-home') },
    { target: '/capture', icon: Camera, name: t('menu-capture') },
    { target: '/identify', icon: Glasses, name: t('menu-identify') },
    { target: '/quiz', icon: CheckMarks, name: t('menu-quiz') },
  ];

  const getBadgeText = (link: string): string => {
    if (link === links[3].target && quizList.availableQuizzesLength > 0) {
      return quizList.availableQuizzesLength.toString();
    }
    return '';
  };

  if (shouldNotShowBottomBar()) {
    return null;
  }

  if (store.user) {
    return (
      <Flex>
        {links.map((link) => (
          <RouteBox
            item={link}
            isActive={link.target === pathname}
            key={link.target}
            badgeText={getBadgeText(link.target)}
          />
        ))}
      </Flex>
    );
  }

  return null;
});
