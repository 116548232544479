import {
  Box, Flex, Grid, GridItem, Text,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { useStore } from '../store';
import { ReactComponent as Back } from '../icons/back.svg';
import { QuizState } from '../store/quiz';

const arrowVisibleStates = [QuizState.PREVIEW, QuizState.PREVIEW_QUESTION];

const shouldShowArrow = (actualState: string) => (
  arrowVisibleStates.some((state) => state === actualState)
);

export const QuizHeader = observer(() => {
  const store = useStore('quiz');
  const history = useHistory();

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap="10px">
      <GridItem colSpan={4} pt="16px">
        <Flex>
          {shouldShowArrow(store.state) && <Box mr="13px"><Back onClick={() => history.goBack()} /></Box>}
          <Text fontWeight="bold">{store.quizName}</Text>
        </Flex>
      </GridItem>
    </Grid>
  );
});
