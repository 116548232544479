import {
  Box, Center, Spinner,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useLocation } from 'react-router';
import { QuizPreview } from '../components/QuizPreview';
import { QuizQuestion } from '../components/QuizQuestion';
import { useStore } from '../store';
import { QuizState } from '../store/quiz';
import { QuizQuestionPreview } from '../components/QuizQuestionPreview';
import { QuizOverview } from '../components/QuizOverview';
import { QuizErrorPage } from '../components/QuizErrorPage';

type Props = RouteComponentProps<{id: string}>;

export const Quiz: FC<Props> = observer(({ match }: Props) => {
  const quiz = useStore('quiz');
  const location = useLocation();

  useEffect(() => () => {
    quiz.reset();
  }, [location, quiz]);

  useEffect(() => {
    quiz.fetchQuiz(match.params.id);
  }, [match.params.id, quiz]);

  return (
    <Box mx="20px">

      {quiz.state === QuizState.LOADING && <Center h="full"><Spinner size="xl" /></Center>}
      {quiz.state === QuizState.ANSWERING && <QuizQuestion />}
      {quiz.state === QuizState.OVERVIEW && <QuizOverview />}
      {quiz.state === QuizState.PREVIEW && <QuizPreview />}
      {quiz.state === QuizState.PREVIEW_QUESTION && <QuizQuestionPreview />}
      {quiz.state === QuizState.ERROR && <QuizErrorPage />}

    </Box>
  );
});
