import ErrorEmitter from './errorEmitter';

// during local development prefix should be empty, but on the production environment
// should be /backend
export const BASE_URL = '/backend';

export const STORAGE_USER_TOKEN_KEY = 'authToken';

const authHeaders = (): Record<'Authorization', string> | Record<string, never> => {
  if (localStorage.getItem(STORAGE_USER_TOKEN_KEY) !== null) {
    return { Authorization: `Bearer ${localStorage.getItem(STORAGE_USER_TOKEN_KEY)}` };
  }
  return {};
};

const apiCaller = (
  {
    url,
    incognito = false,
    external = false,
    ...options
  }: {
    url: string,
    incognito?: boolean,
    external?: boolean
  } & RequestInit,
) => fetch(external ? url : `${BASE_URL}${url}`, {
  ...options,
  headers: {
    Accept: 'application/json',
    ...options.headers,
    ...(!incognito && authHeaders()),
  },
})
  .then(async (response) => {
    if (response.statusText === 'Unauthorized') {
      ErrorEmitter.emitUnauthenticatedError();
    }

    if (!response.ok) {
      /* eslint promise/no-return-wrap: ["error", { allowReject: true }] */
      return response.json().then((data) => Promise.reject(data));
    }

    return response.json();
  });

export const apiGet = <T>(
  url: string,
  options: RequestInit = {},
  incognito = false,
  external = false,
): Promise<T> => apiCaller({
    url,
    incognito,
    external,
    method: 'GET',
    ...options,
  });

export const apiPost = <T>(
  url: string,
  data = {},
  options: RequestInit = {},
): Promise<T> => apiCaller({
    url,
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    ...options,
  });

export const apiPut = <T>(
  url: string,
  data = {},
  options: RequestInit = {},
): Promise<T> => apiCaller({
    url,
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    ...options,
  });

export const apiDelete = <T>(
  url: string,
  options: RequestInit = {},
): Promise<T> => apiCaller({
    url,
    method: 'DELETE',
    ...options,
  });
