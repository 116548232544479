import {
  Button, Center, Flex, Text,
} from '@chakra-ui/react';
import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { BlurredImage } from './BlurredImage';
import { useStore } from '../store';

interface PreviewProps {
  photo: string;
  onSubmit: () => void;
  onClose: () => void;
  uuid: string;
  isError: boolean;
}

export const Preview: FC<PreviewProps> = observer(({
  photo, onClose, onSubmit, uuid, isError,
}: PreviewProps) => {
  const store = useStore('upload');
  const { t } = useTranslation();

  return (
    <Flex px="20px" pt="20px" flexDir="column" h="full">
      <BlurredImage image={photo} alignSelf="center" alt={`Preview ${uuid}`} />

      <Button
        w="full"
        mt="35px"
        onClick={() => onSubmit()}
        isLoading={store.isLoading}
        disabled={isError}
      >
        {t('use-this-photo')}
      </Button>
      <Button
        w="full"
        my="10px"
        variant="outline"
        onClick={() => onClose()}
        isLoading={store.isLoading}
      >
        {t('take-another')}
      </Button>

      {isError && (
        <Center>
          <Text color="tertiary">Some problem occurred. Your storage might be full. Try to upload photos already saved in storage.</Text>
        </Center>
      )}
    </Flex>
  );
});
