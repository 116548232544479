import {
  Box, Text, Button,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export const QuizErrorPage = () => (
  <Box
    px="20px"
    display="flex"
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
    h="100vh"
  >
    <Text fontSize="md" fontWeight="bold" textAlign="center">We are sorry, this quiz has no questions yet!</Text>

    <Button as={Link} to="/quiz" mt="20px">Back to list</Button>
  </Box>
);
