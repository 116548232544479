import { makeAutoObservable } from 'mobx';

type ImageData = {
  image: string,
  alt: string,
};

export class FullImageStore {
  constructor() {
    makeAutoObservable(this);
  }

  isImageOpened = false;

  src = '';

  alt = '';

  openFullImage = (imageData: { image: string; alt: string }) => {
    this.src = imageData.image;
    this.alt = imageData.alt;
    this.isImageOpened = true;
    document.body.classList.add('blurred');
  };

  closeFullImage = () => {
    this.src = '';
    this.alt = '';
    this.isImageOpened = false;
    document.body.classList.remove('blurred');
  };
}
