/* eslint-disable no-else-return */
import { FC } from 'react';
import {
  Box, Flex, Image, Text,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Check } from '../icons/check.svg';
import { ReactComponent as Stroke } from '../icons/stroke.svg';
import { ReactComponent as ArrowRight } from '../icons/arrow-right.svg';
import { Identification } from '../types';

interface Props {
  isWrong: boolean,
  answer: Identification | null,
  index: number,
  image: string,
  isOverview?: boolean,
  onClick: () => void,
}

export const AnsweredQuestionItem:FC<Props> = observer(({
  isWrong, answer, index, image, isOverview = false, onClick,
}: Props) => {
  const { t } = useTranslation();

  const getHeaderText = (): string => {
    if (!isOverview) {
      return `${t('question')} ${index + 1}: ${isWrong ? t('failed') : t('correct')}`;
    }
    return `${t('question')} ${index + 1}`;
  };

  const getTextColor = (): string => {
    if (isOverview) {
      return 'text';
    } else if (isWrong) {
      return 'tertiary';
    }

    return 'primary.one';
  };

  const getSpeciesName = (): any => {
    if (answer) {
      const text = { __html: `${answer?.name}${(answer.name && answer.scientificName) && '  |  '}<i>${answer?.scientificName}</i>` };
      return (
        <Text
          opacity={0.6}
          fontSize="16px"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
        >
          <div
            dangerouslySetInnerHTML={text}
            style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
          />
        </Text>
      );
    }

    return (
      <Text
        opacity={isOverview ? 1 : 0.6}
        fontWeight={isOverview ? 'bold' : 'normal'}
      >
        {t('no-answer')}
      </Text>
    );
  };

  return (
    <Box
      borderRadius="19px"
      borderWidth={isWrong ? '0px' : '2px'}
      borderColor="gray.lightWithOpacity"
      p={isWrong ? '12px' : '10px'}
      bg={isWrong ? 'rgba(208, 52, 153, 0.1)' : 'white'}
      mb="8px"
      boxSizing="border-box"
      onClick={onClick}
    >
      <Flex align="center">
        <Image src={image} w="48px" h="48px" borderRadius="base" mr="16px" fit="cover" />
        <Box flex={1} display="grid">
          <Text
            mb="2px"
            color={getTextColor()}
            display="flex"
            alignItems="center"
          >
            {getHeaderText()}
            {!isOverview && <Box ml="10px">{isWrong ? <Stroke /> : <Check />}</Box>}
          </Text>
          {getSpeciesName()}
        </Box>
        {isOverview && <Box minWidth="10px" mr={2}><ArrowRight /></Box>}
      </Flex>
    </Box>
  );
});
