import { makeAutoObservable } from 'mobx';

export class InputStore {
  constructor() {
    makeAutoObservable(this);
  }

  isInputFocused = false;

  toggleInputFocusState = () => {
    this.isInputFocused = !this.isInputFocused;
  };
}
