import { useContext } from 'react';
import { Store } from './store';
import { StoreContext } from './provider';

type StoreKeys = keyof Store;

type UseStoreMethod = {
  (): Store
  <T extends StoreKeys>(storeName: T): Store[T];
};

export const useStore: UseStoreMethod = <T extends StoreKeys>(storeName?: T) => {
  const store = useContext(StoreContext);

  if (storeName) {
    return store[storeName];
  }

  return store;
};
