import { observer } from 'mobx-react-lite';
import {
  Box, Center, Image, Spinner, Flex, Text,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStore } from '../store';
import { ReactComponent as Back } from '../icons/back.svg';
import { ProfileInfo } from '../components/ProfileInfo';
import { ProfileUploaded } from '../components/ProfileUploaded';

export const Profile = observer(() => {
  const store = useStore('user');
  const history = useHistory();
  const { t } = useTranslation();

  if (!store.user) return <Center><Spinner /></Center>;

  return (
    <Flex maxH="100%" h="100%" pt="8px" boxSithemezing="border-box" direction="column" justify="space-between">
      <Box>
        <Box
          ml="12px"
          mr="20px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Back onClick={() => history.push('/')} />
          <Text
            fontWeight="bold"
            onClick={store.logout}
          >
            {t('log-out')}
          </Text>
        </Box>

        <Box
          mx="20px"
        >
          <Box
            mt="22px"
            pb="22px"
            borderBottomColor="gray.lightWithOpacity"
            borderBottomWidth="2px"
          >
            <Box
              w="72px"
              h="72px"
              borderRadius="100%"
              overflow="hidden"
            >
              <Image
                height="100%"
                maxWidth="none"
                width="100%"
                objectFit="cover"
                src={store.user.avatarUrl}
              />
            </Box>

            <ProfileInfo title={t('profile-name')} content={store.user.name} />
          </Box>
          <ProfileUploaded />

        </Box>

      </Box>
    </Flex>
  );
});
