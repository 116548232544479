import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const MakeOwnObservationsButton = () => {
  const { t } = useTranslation();
  return (
    <Button w="full" as={Link} to="/capture">{t('make-your-own-observation')}</Button>
  );
};
