import { Box, ChakraProps } from '@chakra-ui/react';
import { FC } from 'react';

interface Props extends ChakraProps{
  text: string | number
}

export const Badge:FC<Props> = ({ text, ...props }: Props) => (
  <Box
    w="18px"
    h="18px"
    borderRadius="100%"
    bg="tertiary"
    boxShadow="0px 4px 4px rgba(208, 52, 153, 0.49)"
    display="flex"
    alignItems="center"
    justifyContent="center"
    position="absolute"
    zIndex={1}
    fontSize="12px"
    color="white"
    boxSizing="border-box"
    pb="2px"
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  >
    {text.toString()}
  </Box>
);
