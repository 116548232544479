import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      'identify-species': 'Identify species',
      'sign-in-mit-uib': 'Sign in with Mitt UiB',
      'you-have-new-quiz': 'You have a new quiz!',
      'quiz-added': 'Added',
      'make-your-own-observation': 'Make your own observation',
      'identify-others-photos': 'Identify other’s photos',
      'curious-to-lern-new-species': 'Hello! Curious to learn new species?',
      'take-a-photo': 'Take a photo',
      'name-your-guess': 'Name your guess',
      'share-your-observation': 'Share your observation',
      'menu-home': 'Home',
      'menu-capture': 'Capture',
      'menu-identify': 'Identify',
      'menu-quiz': 'Quiz',
      'log-out': 'Log out',
      'profile-name': 'Name',
      'name-photos-before-upload': 'Name photos before upload',
      'some-photos-are-waiting-for-upload': 'Some photos are waiting for upload',
      'all-photos-uploaded': 'All photos uploaded!',
      'camera-permissions': 'Loading camera, make sure to approve permissions.',
      'use-this-photo': 'Use this photo',
      'take-another': 'Take another',
      'what-is-on-this-photo': "What's on this photo?",
      'optional-comment': 'Comment (optional)',
      'add-comment': 'Add comment',
      'names-suggestions': 'suggestions',
      share: 'Share',
      'identify-the-species': 'Identify the species',
      next: 'Next',
      previous: 'Previous',
      'camera-error-occurred': 'Sorry! An error occurred. Try reload.',
      'no-more-photos-to-identify': 'No more photos to identify.',
      'make-an-observation': 'Make an observation',
      'available-quizzes': 'Available quizzes',
      'there-are-no-quizzes': 'There are no quizzes yet',
      'past-quizzes': 'Past quizzes',
      taken: 'Taken',
      question: 'Question',
      'review-and-change-questions': 'You can review and change your answers before submitting the quiz',
      'no-answer': 'No answer',
      'no-leave-unanswered-questions': 'Leaving answered questions will result in lower grade. Try to answer them all!',
      'submit-your-answers': 'Submit your answers',
      'quiz-results': 'Results',
      'correct-answers': 'Correct answers',
      'questions-answered': 'Questions answered',
      correct: 'Correct',
      failed: 'Failed',
      new: 'NEW',
    },
  },
  no: {
    translation: {
      'identify-species': 'Identifiser arter',
      'sign-in-mit-uib': 'Logg inn med Mitt UiB ',
      'you-have-new-quiz': 'Du har en ny quiz!',
      'quiz-added': 'Lagt til',
      'make-your-own-observation': 'Gjør din egen observasjon',
      'identify-others-photos': 'Identifiser andres bilder',
      'curious-to-lern-new-species': 'Hei! Nysgjerrig på å lære nye arter?',
      'take-a-photo': 'Ta et bilde',
      'name-your-guess': 'Nevn gjetningen din',
      'share-your-observation': 'Del din observasjon',
      'menu-home': 'Hjem',
      'menu-capture': 'Observer',
      'menu-identify': 'Identifisere',
      'menu-quiz': 'Quiz',
      'log-out': 'Logg ut',
      'profile-name': 'Navn',
      'name-photos-before-upload': 'Navngi opplastede bilder',
      'some-photos-are-waiting-for-upload': 'Noen bilder venter på opplasting',
      'all-photos-uploaded': 'Alle bilder lastet opp! ',
      'camera-permissions': 'Laster inn kamera, sørg for å godkjenne tillatelser.',
      'use-this-photo': 'Bruk dette bildet',
      'take-another': 'Ta et nytt',
      'what-is-on-this-photo': 'Hva er på dette bildet? ',
      'optional-comment': 'Kommentar (valgfritt) ',
      'add-comment': 'Legg til kommentar',
      'names-suggestions': 'forslag',
      share: 'Del',
      'identify-the-species': 'Identifiser arten',
      next: 'Neste',
      previous: 'Forrige',
      'camera-error-occurred': 'Unnskyld! En feil oppstod. Prøv å laste inn på nytt.',
      'no-more-photos-to-identify': 'Ingen bilder å identifisere.',
      'make-an-observation': 'Gjør en observasjon',
      'available-quizzes': 'Tilgjengelige quiz',
      'there-are-no-quizzes': 'Det er ingen quiz ennå',
      'past-quizzes': 'Tidligere quiz',
      taken: 'Tatt',
      question: 'Spørsmål',
      'review-and-change-questions': 'Du kan se gjennom og endre svarene dine før du sender inn quizen ',
      'no-answer': 'Ingen svar',
      'no-leave-unanswered-questions': 'Ubesvarte spørsmål vil føre til en lavere karakter. Prøv å svar på dem alle!',
      'submit-your-answers': 'Send inn svar',
      'quiz-results': 'Resultater',
      'correct-answers': 'Riktige svar',
      'questions-answered': 'Spørsmål besvart',
      correct: 'Riktig',
      failed: 'Feil',
      new: 'NY',
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'no',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

// eslint-disable-next-line import/no-default-export
export default i18n;
