import {
  PropsWithChildren, FC, useEffect, useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import ErrorEmitter, { UNAUTHENTICATED } from '../utils/errorEmitter';
import { useStore } from '../store';

export const OAuthErrorHandler:FC = ({ children }: PropsWithChildren<any>) => {
  const store = useStore('user');
  const history = useHistory();

  const goToLoginPage = useCallback(() => {
    store.user = null;
    history.push('/login');
  }, [history, store]);

  useEffect(() => {
    ErrorEmitter.on(UNAUTHENTICATED, goToLoginPage);

    return () => {
      ErrorEmitter.removeEventListener(UNAUTHENTICATED, goToLoginPage);
    };
  }, [goToLoginPage]);

  return (
    <>
      {children}
    </>
  );
};
