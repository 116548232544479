import { makeAutoObservable } from 'mobx';
import { Paginated } from '../../types';
import { apiGet } from '../../utils/api';

export type QuizStatus = 'ready' | 'submitted' | 'closed';

export interface ApiQuiz {
  uuid: string;
  title: string;
  status: QuizStatus;
  created_at: string;
  owner: {
    uuid: string;
    name: string;
    avatar?: string;
  }
  submissions: Submission[];
}

interface Submission {
  created_at: string
}

export interface Quiz {
  id: string;
  title: string;
  status: QuizStatus;
  createdAt: string;
  taken: string;
}

export class QuizList {
  quizzes: Quiz[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  async fetch(): Promise<void> {
    try {
      const { data } = await apiGet<Paginated<ApiQuiz>>('/api/quizzes');
      if (Array.isArray(data)) {
        this.quizzes = data.map((quiz) => {
          let taken = '';
          if (quiz.submissions.length > 0) {
            taken = quiz.submissions[0].created_at;
          }

          return {
            id: quiz.uuid,
            title: quiz.title,
            status: quiz.status,
            createdAt: quiz.created_at,
            taken,
          };
        });
      }
      return Promise.resolve();
    } catch (error) {
      return Promise.reject();
    }
  }

  submitted(): Quiz[] {
    return this.quizzes.filter((quiz) => quiz.status === 'submitted');
  }

  available(): Quiz[] {
    return this.quizzes.filter((quiz) => quiz.status === 'ready');
  }

  get availableQuizzesLength(): number {
    return this.available().length;
  }
}
