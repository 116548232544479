import { EventEmitter } from 'events';

export const UNAUTHENTICATED = 'unauthenticated';

class ErrorEmitter {
  events: any;

  constructor() {
    this.events = new EventEmitter();
  }

  emitUnauthenticatedError() {
    this.events.emit(UNAUTHENTICATED);
  }

  on(...args: any[]) {
    return this.events.on(...args);
  }

  removeListener(...args: any[]) {
    return this.events.removeListener(...args);
  }

  static getInstance() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!ErrorEmitter.instance) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ErrorEmitter.instance = new ErrorEmitter();
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return ErrorEmitter.instance;
  }
}

const ErrorEmitterInstance = ErrorEmitter.getInstance();

// eslint-disable-next-line import/no-default-export
export default ErrorEmitterInstance;
export { ErrorEmitterInstance };
