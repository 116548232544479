import { rest } from 'msw';

export const handlers = [
  rest.get('/api/auth/complete', async (req, res, ctx) => {
    const token = Buffer.from('test1234').toString('base64');

    return res(ctx.json({ token }));
  }),

  rest.get('/api/auth/whoami', async (_req, res, ctx) => res(ctx.json({
    name: 'User name',
    avatar_url: 'https://example.com/avatar.png',
  }))),

  rest.post('/api/observations', async (_req, res, ctx) => res(ctx.json({
    uuid: '7',
  }))),

  rest.get('/api/observations', async (_req, res, ctx) => res(ctx.json({
    data: [{
      uuid: '7',
      type: 'image',
      photo: 'https://example.com/7.png',
      description: 'test',
      names: [{ name: 'buestarr', language: 'no' }, { name: 'Carex maritima', language: 'la' }],
      owner: { uuid: '7', name: 'User name', avatar: 'https://example.com/avatar.png' },
    }],
    meta: {
      current_page: 1,
      last_page: 1,
    },
  }))),

  rest.get('/api/quizzes', async (_req, res, ctx) => res(ctx.json({
    data: [
      {
        uuid: '1',
        title: 'test',
        status: 'submitted',
        created_at: '12 January 2021',
        owner: { uuid: '7', name: 'User name', avatar: 'https://example.com/avatar.png' },
      },
      {
        uuid: '7',
        title: 'new test',
        status: 'ready',
        created_at: '11 January 2021',
        owner: { uuid: '7', name: 'User name', avatar: 'https://example.com/avatar.png' },
      },
    ],
    meta: {
      current_page: 1,
      last_page: 1,
    },
  }))),
];
