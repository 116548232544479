import { makeAutoObservable } from 'mobx';
import { ApiObservation, Identification, Paginated } from '../../types';
import { apiGet, apiPost } from '../../utils/api';
import { Observation } from './observation';

export class ObservationsStore {
  observations: Observation[] = [];

  currentObservationIndex = 0;

  isLoading = false;

  isFetching = true;

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.observations = [];
    this.currentObservationIndex = 0;
    this.isLoading = false;
    this.isFetching = true;
  };

  async fetchObservations() {
    try {
      const { data } = await apiGet<Paginated<ApiObservation>>('/api/observations');
      this.observations = data.map(
        ({ uuid, photo }) => new Observation(
          uuid,
          photo,
          null,
        ),
      );
      this.isFetching = false;
    } catch (error) {
      this.isFetching = false;
      console.error(error);
    }
  }

  goNext = async (): Promise<void> => {
    if (this.hasIdentification()) {
      await this.sendIdentification();
    } else if (this.canGoNext) {
      this.currentObservationIndex += 1;
    }
  };

  sendIdentification = async (): Promise<void> => {
    this.isLoading = true;
    const { identification } = this.currentObservation;
    const names = {
      no: identification?.name || null,
      la: identification?.scientificName || null,
    };
    try {
      await apiPost(
        `/api/observations/${this.currentObservation.id}/identifications`,
        { names },
      );

      this.observations = [...this.toIdentify()];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      console.error(error);
    }
  };

  setIdentification = (identification: Identification | null): void => {
    this.currentObservation.identification = identification;
  };

  hasIdentification = (): boolean => this.currentObservation.identification !== null;

  goBack = (): void => {
    if (this.canGoBack) {
      this.currentObservationIndex -= 1;
    }
  };

  get canGoBack(): boolean {
    return this.currentObservationIndex !== 0;
  }

  get canGoNext(): boolean {
    return this.observations.length - 1 > this.currentObservationIndex;
  }

  get currentObservation(): Observation {
    return this.observations[this.currentObservationIndex];
  }

  toIdentify(): Observation[] {
    return this.observations.filter((observation) => !observation.identification);
  }
}
