/* eslint-disable react/jsx-props-no-spreading */
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useStore } from '../store';

export const ProtectedRoute: FC<RouteProps> = observer(({ component: Component, ...props }) => {
  const store = useStore('user');

  if (!Component) return null;

  if (!store.user) return <Redirect to="/login" />;

  return (
    <Route
      {...props}
      render={(componentProps) => <Component {...componentProps} />}
    />
  );
});
