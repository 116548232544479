import React from 'react';
import {
  Text,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../store';
import { QuizHeader } from './QuizHeader';
import { AnsweredQuestionItem } from './AnsweredQuestionItem';
import { QuizQuestion } from '../types';

export const QuizOverview = observer(() => {
  const store = useStore('quiz');

  return (
    <>
      <QuizHeader />

      <Text mt={2} mb={4}>{`${store.questions.length} questions — ${store.questionsWithAnswersLength} answers`}</Text>

      {store.questions.map((question: QuizQuestion, index: number) => (
        <AnsweredQuestionItem
          isWrong={false}
          isOverview
          answer={question.answer}
          key={question.id}
          index={index}
          image={question.image}
          onClick={() => store.openQuestion(index)}
        />
      ))}

    </>
  );
});
