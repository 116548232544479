import {
  Box, ChakraProps, Image,
} from '@chakra-ui/react';
import {
  FC, PropsWithChildren,
} from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../store';

interface Props extends ChakraProps {
  image: string;
  size?: number;
  alt?: string;
  allowZoom?: boolean;
  isDashboard?: boolean;
}

export const BlurredImage: FC<Props> = observer(({
  image,
  children,
  alt,
  allowZoom = false,
  size = 600,
  isDashboard = false,
  ...props
}: PropsWithChildren<Props>) => {
  const inputStore = useStore('input');
  const { openFullImage } = useStore('fullImageStore');

  const openZoomed = () => {
    const imageData = {
      image,
      alt: alt || '',
    };
    openFullImage(imageData);
  };

  return (
    <Box
      pos="relative"
      w="full"
      maxW={`${size}px`}
      maxH={`${isDashboard ? 180 : size}px`}
      style={{ transition: '0.3s cubic-bezier(0.16, 0, 0.16, 1)' }}
      width={inputStore.isInputFocused ? '73px' : '100%'}
      _after={{ content: '""', display: 'block', paddingBottom: '100%' }}
      borderRadius="2xl"
      onClick={allowZoom ? openZoomed : undefined}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
    >
      <Image
        src={image}
        fit="cover"
        w="full"
        h="100%"
        pos="absolute"
        filter="blur(4vw) saturate(150%)"
        transform="translateY(4vw)"
        opacity={0.75}
        pointerEvents="none"
        userSelect="none"
      />

      <Image
        fit="cover"
        src={image}
        alt={alt}
        id={alt}
        borderRadius="2xl"
        pos="absolute"
        w="full"
        h="full"
        zIndex={1}
        cursor={allowZoom ? 'pointer' : 'default'}
        userSelect="none"
        pointerEvents="none"
      />

      {children && <Box pos="absolute" inset={0} zIndex={1} w="100%" bottom="0px">{children}</Box>}
    </Box>

  );
});
