import { Text, TextProps, useTheme } from '@chakra-ui/react';
import { FC } from 'react';

interface Props extends TextProps {
  children: string;
  highlight: string;
}

const stringsEqual = (one: string, two: string) => one.toLowerCase() === two.toLowerCase();

export const HighlightText: FC<Props> = ({ children, highlight, ...props }: Props) => {
  const parts = children.split(new RegExp(`(${highlight})`, 'gi'));
  const theme = useTheme();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Text color="gray.dark" {...props}>
      {parts.map((part, i) => (
        <span
          // we can only distinguish parts by it's content AND index
          // (otherwise we can get duplicate keys)
          key={`${part}-${i}`} // eslint-disable-line react/no-array-index-key
          style={{ color: stringsEqual(part, highlight) ? theme.colors.text : 'inherit' }}
        >
          {part}
        </span>
      ))}
    </Text>
  );
};
