import {
  Box, Center, Flex, Spinner,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import './App.css';
import { BottomBar } from './components/BottomBar';
import { Router } from './Router';
import { useStore } from './store';
import { QuizOverviewBottomBar } from './components/QuizOverviewBottomBar';
import { FullScreenImage } from './components/FullScreenImage';
import { STORAGE_USER_TOKEN_KEY } from './utils/api';

export const App: FC = observer(() => {
  const store = useStore('user');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (localStorage.getItem(STORAGE_USER_TOKEN_KEY)) {
      setLoading(true);
      // eslint-disable-next-line promise/catch-or-return
      store.getCurrentUser().finally(() => setLoading(false));
    }
  }, [store]);

  return (
    <Flex direction="column" h="full" className="container">
      <Box w="full" flex={1} overflowY="auto">
        {loading
          ? <div id="loader"><Center h="full"><Spinner size="xl" /></Center></div>
          : <Router />}
      </Box>
      <FullScreenImage />
      <BottomBar />
      <QuizOverviewBottomBar />
    </Flex>
  );
});
