import { FIRST_OPEN_TIMESTAMP } from './shouldShowOnFirstOpen';

export const setFirstOpenTime = (): void => {
  const firstOpenTimestamp = localStorage.getItem(FIRST_OPEN_TIMESTAMP);

  if (!firstOpenTimestamp) {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    localStorage.setItem(FIRST_OPEN_TIMESTAMP, JSON.stringify(date.getTime()));
  }
};
