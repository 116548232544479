import {
  Divider, Flex, Input, Text, Box, FormLabel, useDisclosure, Fade,
} from '@chakra-ui/react';
import {
  FC, useCallback, useEffect, useState,
} from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useDebounce } from '../hooks/useDebounce';
import { Identification } from '../types';
import { AutocompleteResult } from '../types/autocomplete';
import { apiGet } from '../utils/api';
import { HighlightText } from './HighlightText';
import { useStore } from '../store';

interface Props {
  value: Identification | null;
  onChange: (value: Identification | null) => void;
  // eslint-disable-next-line react/require-default-props
  placeholder?: string;
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean
}

export const SpeciesSearch: FC<Props> = observer(({
  value, onChange, placeholder = '', disabled = false,
}: Props) => {
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 300);
  const [results, setResults] = useState<AutocompleteResult[]>([]);
  const inputStore = useStore('input');
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    function closeSearchIfTapOutside(e: MouseEvent) {
      if (isSearchOpen) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const isSpeciesSearch = e.target.id === 'species-search';
        if (!isSpeciesSearch) {
          setIsSearchOpen(false);
        }
      }
    }
    document.addEventListener('click', closeSearchIfTapOutside);
    return () => {
      document.removeEventListener('click', closeSearchIfTapOutside);
    };
  }, [isSearchOpen]);

  useEffect(() => {
    if (debouncedSearch.length < 3) {
      setResults([]);
    } else {
      setIsSearchOpen(true);
      const url = `https://artskart.artsdatabanken.no/appapi/api/data/SearchTaxons?maxCount=20&name=${debouncedSearch}`;
      apiGet<AutocompleteResult[]>(url, {}, true, true)
        .then((data) => setResults(data))
        .catch(() => setResults([]));
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (value) {
      setSearch(`${value.name} (${value.scientificName})`);
    } else {
      setSearch('');
    }
  }, [value]);

  const changeValue = (result: AutocompleteResult | null) => {
    if (result) {
      onChange({ name: result.PopularName, scientificName: result.ScientificName });
    } else {
      onChange(null);
    }
    setIsSearchOpen(false);
  };
  return (
    <Box>
      <Box
        position="relative"
      >
        <Input
          autocomplete="off"
          type="text"
          bg="white"
          placeholder={placeholder}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          size="lg"
          h="64px"
          borderWidth="2px"
          fontSize="md"
          disabled={disabled}
          onFocus={() => {
            inputStore.toggleInputFocusState();
            setIsSearchOpen(true);
          }}
          onBlur={inputStore.toggleInputFocusState}
          id="species-search"

        />
        <Fade in={Boolean(search)}>
          <Box
            position="absolute"
            p="2px"
            left="10px"
            top="-12px"
            background="linear-gradient(0deg, rgba(255,255,255,1) 50%, rgba(253,187,45,0) 100%)"
            zIndex={2}
          >
            <FormLabel
              fontSize="smallMedium"
              opacity={0.6}
              lineHeight="150%"
              m={0}
            >
              {placeholder}
            </FormLabel>
          </Box>

        </Fade>

      </Box>

      {(isSearchOpen && results.length > 0) && (
        <Box>
          <Text m={1} fontWeight="bold" fontSize="small" color="gray.mid">{t('names-suggestions')}</Text>

          {results.map((result, index) => (
            <Flex
              key={result.Id}
              flexDir="column"
              onClick={() => changeValue(result)}
              minHeight="44px"
              justifyContent="center"
              borderBottomColor="gray.lightest"
              borderBottomWidth={index < results.length - 1 ? '1px' : '0px'}
              py="8px"
            >
              <HighlightText highlight={debouncedSearch}>{result.PopularName}</HighlightText>
              <HighlightText highlight={debouncedSearch} fontStyle="italic">{result.ScientificName}</HighlightText>
            </Flex>
          ))}
        </Box>
      )}

    </Box>
  );
});
