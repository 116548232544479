/* eslint-disable global-require, @typescript-eslint/no-var-requires */
import { ChakraProvider } from '@chakra-ui/react';
import { FC, StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import { App } from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { StoreProvider } from './store';
import { theme } from './utils/theme';
import { OAuthErrorHandler } from './components/OAuthErrorHandler';

const render = async (RootComponent: FC) => {
  // you can use only one service worker, you have either mocks or offline mode
  if (process.env.REACT_APP_USE_MOCKS === 'true') {
    const { worker } = require('./mocks/browser');

    await worker.start();
  } else {
    serviceWorkerRegistration.register();
  }

  ReactDOM.render(
    <StrictMode>
      <ChakraProvider theme={theme}>
        <StoreProvider>
          <BrowserRouter>
            <OAuthErrorHandler>
              <RootComponent />
            </OAuthErrorHandler>
          </BrowserRouter>
        </StoreProvider>
      </ChakraProvider>
    </StrictMode>,
    document.getElementById('root'),
  );
  const loadingElement = document.getElementById('loading-app-indicator');
  if (loadingElement !== null) {
    loadingElement.remove();
  }
};

render(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp: typeof App = require('./App').default;

    render(NextApp);
  });
}
