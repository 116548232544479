import { Center, Flex, Text } from '@chakra-ui/react';
import { ReactComponent as Warning } from '../icons/warning.svg';

interface Props {
  text: string,
}

export const CameraError = ({ text }: Props) => (
  <Center pos="absolute" h="full" w="full" padding="0px 20px 0px 20px">
    <Flex
      px="17px"
      py="17px"
      background="rgba(255, 255, 255, 0.9)"
      borderColor="white"
      borderWidth="1px"
      borderRadius="9px"
    >
      <Warning />
      <Text
        ml="12px"
        fontSize="smallMedium"
      >
        {text}
      </Text>
    </Flex>
  </Center>
);
