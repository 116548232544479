export const FIRST_OPEN_TIMESTAMP = 'firstOpenTimestamp';

export const shouldShowOnFirstOpen = (): boolean => {
  const firstOpenTimestamp = localStorage.getItem(FIRST_OPEN_TIMESTAMP) || '';

  const timestampType = typeof parseInt(firstOpenTimestamp, 10);
  if (timestampType === 'number') {
    const date = new Date();
    const timestamp = parseInt(firstOpenTimestamp, 10);
    const futureDate = new Date(timestamp);

    if (!Number.isNaN(timestamp) && typeof futureDate === 'object') {
      return date.getTime() < futureDate.getTime();
    }
    return false;
  }
  return false;
};
