import { Box, Text, Center } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { QuizCard } from '../components/QuizCard';
import { useStore } from '../store';

export const QuizList: FC = observer(() => {
  const store = useStore('quizList');
  const { t } = useTranslation();

  useEffect(() => {
    store.fetch();
  }, [store]);

  if (store.available().length === 0 && store.submitted().length === 0) {
    return (
      <Box p={4}>
        <Center w="100%">
          <Text as="h2" mb={4} fontWeight="bold">{t('there-are-no-quizzes')}</Text>
        </Center>
      </Box>
    );
  }

  return (
    <Box p={4}>
      {store.available().length > 0 && (
      <Box mb="50px">
        <Text as="h2" mb={4} fontWeight="bold">{t('available-quizzes')}</Text>

        <Box mb={4}>
          {store.available().map((quiz) => (
            <QuizCard key={quiz.id} quiz={quiz} />
          ))}
        </Box>
      </Box>
      )}

      {store.submitted().length > 0 && (
        <div>
          <Text as="h2" mb={4} fontWeight="bold">{t('past-quizzes')}</Text>

          <Box mb={4}>
            {store.submitted().map((quiz) => (
              <QuizCard key={quiz.id} quiz={quiz} taken />
            ))}
          </Box>
        </div>
      )}

    </Box>
  );
});
