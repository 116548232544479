import { observer } from 'mobx-react-lite';
import {
  Grid, GridItem, Box, Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../store';
import { AnsweredQuestionItem } from './AnsweredQuestionItem';
import { QuizHeader } from './QuizHeader';
import { QuizAnsweredQuestion } from '../types';

export const QuizPreview = observer(() => {
  const store = useStore('quiz');
  const { t } = useTranslation();

  return (
    <>
      <QuizHeader />
      <Grid templateColumns="repeat(4, 1fr)" gap="10px" pt="50px" borderColor="gray.lightWithOpacity" borderTopWidth="2px" mt="16px">
        <GridItem colSpan={4} pb="10px" borderColor="gray.lightWithOpacity" borderBottomWidth="2px">
          <Box>
            <Text mb="10px" fontWeight="bold">{t('quiz-results')}</Text>
            <Text mb="10px">{`${t('correct-answers')}: ${store.correctAnswered}/${store.submittedQuestionsCount}`}</Text>
            <Text>{`${t('questions-answered')}: ${store.submittedQuestionsWithAnswers}/${store.submittedQuestionsCount}`}</Text>

            <Text fontSize="smallMedium" mt="55px" opacity={0.6}>{`${t('taken')} ${store.taken}`}</Text>
          </Box>
        </GridItem>
      </Grid>

      <Grid templateColumns="repeat(4, 1fr)" gap="10px" mt="32px">
        <GridItem colSpan={4} pb="10px">
          <Box>
            {store.questionsSubmitted.map((question: QuizAnsweredQuestion, index: number) => (
              <AnsweredQuestionItem
                isWrong={!question.correct}
                answer={question.yourAnswer}
                key={question.id}
                index={index}
                image={question.image}
                onClick={() => store.openQuestionPreview(index)}
              />
            ))}
          </Box>
        </GridItem>
      </Grid>
    </>
  );
});
