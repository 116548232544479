import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Box, Button, Heading, Text,
} from '@chakra-ui/react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useStore } from '../store';
import { ReactComponent as Logo } from '../icons/universityLogo.svg';
import loginBackground from '../images/loginBackground.png';

interface StateType {
  error: string | undefined
}

export const Login: FC = observer(() => {
  const store = useStore('user');
  const location = useLocation<StateType>();
  const { t } = useTranslation();
  if (store.user) return <Redirect to="/" />;

  return (
    <Box
      px="20px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      backgroud="red"
      w="100vw"
      h="100vh"
      position="relative"
      backgroundImage={`url(${loginBackground})`}
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      backgroundSize="cover"
    >

      <Box
        bg="white"
        borderRadius="39px"
        w="full"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        py="54px"
        zIndex={1}
        position="relative"
      >
        <Logo />

        <Heading
          as="h1"
          fontSize="md"
          mt="12px"
          mb="36px"
          lineHeight="120%"
        >
          {t('identify-species')}
        </Heading>

        <Button onClick={() => { store.redirectToLoginPage(); }}>
          {t('sign-in-mit-uib')}
        </Button>

        {location.state?.error && (
          <Text
            color="tertiary"
            fontSize="smallMedium"
            position="absolute"
            bottom="10px"
          >
            {location.state.error}
          </Text>
        )}

      </Box>
    </Box>
  );
});
