import { makeAutoObservable } from 'mobx';
import { Identification } from '../../types';

export class Observation {
  constructor(
    public id: string,
    public photo: string,
    public identification: Identification | null,
  ) {
    makeAutoObservable(this);
  }

  identify(identification: Identification): void {
    this.identification = identification;
  }
}
