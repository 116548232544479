/* eslint-disable react-hooks/exhaustive-deps */
import {
  Flex, Text, Box, Spinner, Center,
} from '@chakra-ui/react';
import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { BlurredImage } from '../components/BlurredImage';
import { SpeciesSearch } from '../components/SpeciesSearch';
import { useStore } from '../store';
import { NoMoreIdentificationsAvailable } from '../components/NoMoreIdentificationsAvailable';
import { NextPreviousButtons } from '../components/NextPreviousButtons';

export const Identify: FC = observer(() => {
  const observationsStore = useStore('observations');
  const { t } = useTranslation();

  useEffect(() => {
    observationsStore.fetchObservations();
  }, []);

  useEffect(() => () => {
    observationsStore.reset();
  }, []);

  if (observationsStore.isFetching) {
    return (
      <Center h="full"><Spinner size="xl" /></Center>
    );
  }

  return (
    <Flex p={4} h="full" flexDir="column">
      <Text fontWeight="bold" mb="16px">{t('identify-the-species')}</Text>

      {observationsStore.observations.length === 0 ? (
        <NoMoreIdentificationsAvailable />
      ) : (
        <>
          <Box position="relative">
            <BlurredImage
              image={observationsStore.currentObservation.photo}
              allowZoom
            />
            {observationsStore.isLoading && (
            <Box
              position="absolute"
              w="full"
              h="full"
              display="flex"
              alignItems="center"
              justifyContent="center"
              top={0}
              zIndex={1}
              background="white"
              opacity={0.7}
              borderRadius="2xl"
            >
              <Spinner size="xl" />
            </Box>
            )}

          </Box>

          <Box
            mt="30px"
            mb="25px"
          >
            <SpeciesSearch
              placeholder={t('what-is-on-this-photo')}
              value={observationsStore.currentObservation.identification}
              onChange={observationsStore.setIdentification}
              disabled={observationsStore.isLoading}
            />
          </Box>

          <NextPreviousButtons
            goNext={observationsStore.goNext}
            goBack={observationsStore.goBack}
            shouldDisplayPrevious={observationsStore.canGoBack}
            shouldDisplayNext={observationsStore.canGoNext
            || observationsStore.observations.length === 1}
            isDisabled={observationsStore.isLoading}
          />

        </>
      )}
    </Flex>
  );
});
