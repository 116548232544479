import { GridItem, Grid, Button } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  goNext: () => void;
  goBack: () => void;
  shouldDisplayPrevious: boolean,
  // eslint-disable-next-line react/require-default-props
  shouldDisplayNext?: boolean
  // eslint-disable-next-line react/require-default-props
  isDisabled?: boolean
}

export const NextPreviousButtons: FC<Props> = ({
  goNext, goBack, shouldDisplayNext = true, shouldDisplayPrevious, isDisabled = false,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Grid
      templateColumns="repeat(4, 1fr)"
      gap="10px"
      mb="26px"
    >
      <GridItem colSpan={2}>
        {shouldDisplayPrevious && (
        <Button
          w="full"
          variant="outline"
          onClick={goBack}
          isDisabled={isDisabled}
        >
          {t('previous')}
        </Button>
        )}
      </GridItem>

      <GridItem colSpan={2}>
        {shouldDisplayNext && (
        <Button
          w="full"
          onClick={goNext}
          isDisabled={isDisabled}
        >
          {t('next')}
        </Button>
        )}
      </GridItem>
    </Grid>
  );
};
