/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box, Center, Flex, Spinner, Text,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../store';
import { ReactComponent as Upload } from '../icons/upload.svg';
import { ReactComponent as CheckDark } from '../icons/checkDark.svg';
import { PhotoWithLoader } from './PhotoWithLoader';
import { PhotoStatus } from '../types';

export const ProfileUploaded = observer(() => {
  const store = useStore('upload');
  const { t } = useTranslation();

  useEffect(() => {
    store.init();
  }, []);

  const isFailurePhoto = () => store.photos.some((photo) => photo.status === PhotoStatus.failure);

  const getHeaderText = (): string => {
    if (store.unnamedPhotosLength > 0) {
      return t('name-photos-before-upload');
    }
    if (isFailurePhoto()) {
      return t('some-photos-are-waiting-for-upload');
    }
    return t('all-photos-uploaded');
  };
  if (store.isLoading) {
    return (
      <Center mt="24px">
        <Spinner size="md" color="tertiary" />
      </Center>
    );
  }

  return (
    <Box
      mt="24px"
    >
      <Flex alignItems="center">
        {store.unnamedPhotosLength > 0 || isFailurePhoto() ? <Upload /> : <CheckDark /> }
        <Text ml="10px" fontWeight="bold">{getHeaderText()}</Text>
      </Flex>

      <Flex flexWrap="wrap" mt="24px">
        {store.photos.map((photo) => (
          <PhotoWithLoader
            image={photo.image}
            progress={photo.progress}
            key={photo.localUUID}
            id={photo.id}
            status={photo.status}
            localUUID={photo.localUUID}
          />
        ))}
      </Flex>
    </Box>
  );
});
