import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { ReactComponent as Close } from '../icons/close.svg';
import { useStore } from '../store';

export const FullScreenImage = observer(() => {
  const {
    isImageOpened, src, alt, closeFullImage,
  } = useStore('fullImageStore');
  return (
    <>
      {isImageOpened && (
        <Box
          id="full-zoom-image"
          pos="fixed"
          left="0px"
          top="0px"
          bottom="0px"
          right="0px"
          background="rgba(0, 0, 0, 0.7)"
          zIndex={3}
        >
          <Box
            position="absolute"
            right="20px"
            top="16px"
            onClick={closeFullImage}
          >
            <Close />
          </Box>
          <Image
            fit="contain"
            src={src}
            alt={alt}
            inset={0}
            w="full"
            h="full"
            cursor="pointer"
          />
        </Box>
      )}
    </>
  );
});
