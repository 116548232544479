/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { observer } from 'mobx-react-lite';
import { Capture } from '../components/Capture';
import { Preview } from '../components/Preview';
import { IdentifyCapturedPhoto } from '../components/IdentifyCapturedPhoto';
import { useStore } from '../store';
import { SerializedPhoto } from '../types';

type ObserveState = 'capture' | 'preview' | 'identify';

interface StateType {
  localUUID: string | undefined
}

export const Observe: FC = observer(() => {
  const [photo, setPhoto] = useState<string | null>(null);
  const [state, setState] = useState<ObserveState>('capture');
  const [localUUID, setLocalUUID] = useState<string>(uuidv4());
  const [indexDbError, setIndexDbError] = useState<boolean>(false);
  const store = useStore('upload');
  const location = useLocation<StateType>();
  const history = useHistory();

  useEffect(() => {
    const uuid = location.state?.localUUID;

    async function setPhotoFromStorage() {
      if (uuid !== undefined) {
        setLocalUUID(uuid);
        try {
          const searchedPhoto: SerializedPhoto = await store.getPhotoFromIndexDb(uuid);
          setPhoto(searchedPhoto.image);
          setState('identify');
        } catch (error) {
          history.push('/');
        }
      }
    }
    setPhotoFromStorage();
  }, []);

  const saveImage = (): Promise<void> => new Promise((resolve, reject) => {
    store.addPhotoToIndexDb(photo, localUUID).then(() => {
      setState('identify');
      setIndexDbError(false);
      return resolve();
    }).catch(() => {
      setIndexDbError(true);
      return reject();
    });
  });

  if (photo) {
    if (state === 'preview') {
      return (
        <Preview
          photo={photo}
          uuid={localUUID}
          isError={indexDbError}
          onClose={() => {
            setPhoto(null);
            setState('capture');
          }}
          onSubmit={saveImage}
        />
      );
    }

    if (state === 'identify') {
      return (
        <IdentifyCapturedPhoto
          onClose={() => {
            setPhoto(null);
            setLocalUUID(uuidv4());
            setState('capture');
          }}
          photo={photo}
          uuid={localUUID}
        />
      );
    }
  }

  return (
    <Capture
      onCapture={(newPhoto) => {
        setPhoto(newPhoto);
        setState('preview');
      }}
    />
  );
});
