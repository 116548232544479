import {
  Box, Center, IconButton, Text,
} from '@chakra-ui/react';
import { ChangeEvent, FC, useState } from 'react';
import { useCallbackRef } from 'use-callback-ref';
import { useTranslation } from 'react-i18next';
import { useMediaStream } from '../hooks/useMediaStream';
import { ReactComponent as CaptureIcon } from '../icons/capture.svg';
import { ReactComponent as Frame } from '../icons/frame.svg';
import { captureFrameFromPreview, captureFrameFromStream } from '../utils/video';
import { CameraError } from './CameraError';

interface CaptureProps {
  onCapture: (data: string) => void;
}

export const Capture: FC<CaptureProps> = ({ onCapture }: CaptureProps) => {
  const constraints = { video: { facingMode: 'environment' }, audio: false };
  const mediaStream = useMediaStream(constraints);
  const [captureError, setCaptureError] = useState<boolean>(false);
  const { t } = useTranslation();

  const video = useCallbackRef<HTMLVideoElement>(null, () => {
    const { current } = video;

    if (current) {
      if (mediaStream) {
        if (!current.srcObject || (current.srcObject as MediaStream).id !== mediaStream.id) {
          current.srcObject = mediaStream;
        }
      } else {
        current.srcObject = null;
      }
    }
  });

  const handleCanPlay = () => {
    video.current?.play();
  };

  const capturePhoto = (): Promise<void> => {
    const track = mediaStream?.getVideoTracks()[0];

    // use winow.ImageCapture if available, it offers much better quality than
    // available from capturing preview frame
    return new Promise((resolve, reject) => {
      if (track && (window as any).ImageCapture) {
        captureFrameFromStream(track).then((response) => {
          onCapture(response);
          setCaptureError(false);
          return resolve();
        }).catch(() => {
          setCaptureError(true);
          return reject();
        });
      } else if (video.current) {
        captureFrameFromPreview(video.current).then((response) => {
          onCapture(response);
          setCaptureError(false);
          return resolve();
        }).catch(() => {
          setCaptureError(true);
          return reject();
        });
      }
    });
  };

  function handleFileChange(e: ChangeEvent<HTMLInputElement>) {
    const { files } = e.target;

    if (files && files.length > 0) {
      const fileReader = new FileReader();

      fileReader.onload = () => {
        if (typeof fileReader.result === 'string') {
          onCapture(fileReader.result);
        }
      };

      fileReader.readAsDataURL(files[0]);
    }
  }

  return (
    <Box position="relative" h="full" background="#aaa">
      <Box pos="absolute" top={0} bottom={0} w="full" h="full" overflowY="hidden">
        {mediaStream
          ? <video style={{ width: '100%', height: '100%' }} ref={video} onCanPlay={handleCanPlay} autoPlay playsInline muted />
          : <CameraError text={t('camera-permissions')} />}
      </Box>

      {captureError && (
        <CameraError text={t('camera-error-occurred')} />
      )}

      <Center pos="absolute" h="full" w="full">
        <Frame />
      </Center>

      <Box
        pos="absolute"
        bottom="35px"
        w="full"
        display="flex"
        justifyContent="center"
      >
        <IconButton
          aria-label="Capture"
          isRound
          w="80px"
          h="80px"
          onClick={capturePhoto}
          disabled={!mediaStream}
          icon={<CaptureIcon />}
        />
      </Box>

      <Box
        pos="absolute"
        bottom="24px"
        right="30px"
        overflow="hidden"
      >
        <IconButton
          variant="outline"
          aria-label="Capture"
          w="65px"
          h="64px"
          background="rgba(45, 59, 69, 0.33)"
          isRound
          size="md"
          onClick={capturePhoto}
          icon={<CaptureIcon fill="white" />}
        />
        <Box
          as="input"
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          cursor="pointer"
          opacity={0}
          inset={0}
          position="absolute"
        />
      </Box>
    </Box>
  );
};
