import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  styles: {
    global: {
      'html, body': {
        fontSize: 'base',
        color: 'text',
        lineHeight: '120%',
      },
    },
  },
  fonts: {
    body: "'Lato', sans-serif",
    heading: "'Lato', sans-serif",
    mono: 'Menlo, monospace',
  },
  colors: {
    primary: {
      one: '#81D96F',
      two: '#54A0B5',
      three: '#799A60',
    },
    progress: {
      500: '#81D96F',
    },
    progressPaused: {
      500: '#B7B7B7',
    },
    progressFailed: {
      500: '#D03499',
    },
    tertiary: '#D03499',
    text: '#2D3B45',
    links: '#008EE2',
    green: '#A7E29A',
    gray: {
      lightest: '#F5F5F5',
      light: '#C7CDD1',
      lightWithOpacity: '#EDEDED',
      mid: '#B7B7B7',
      semiMid: '#61717C',
      dark: '#606060',
      darkest: '#383838',
    },
    beige: {
      neutral: '#EAE6E2',
    },
  },
  fontSizes: {
    smallest: '12px',
    smallMedium: '14px',
    small: '15px',
    base: '18px',
    md: '18px',
    h3: '18px',
    h2: '26px',
    h1: '37px',
  },
  fontWeights: {
    h3: 700,
    h2: 400,
    h1: 700,
  },
  borderRadius: {
    base: '8px',
  },
  components: {
    Button: {
      baseStyle: {
        py: 6,
        lineHeight: '0px',
      },
      variants: {
        solid: {
          bg: '#A7E29A',
          _hover: {
            bg: '#A7E29A',
          },
        },

        outline: {
          bg: '#FFFFFF',
          border: '2px',
          borderColor: '#A7E29A',
        },
      },
    },
  },
});
