import React from 'react';
import { Box, Button, Text } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStore } from '../store';
import { QuizState } from '../store/quiz';

export const QuizOverviewBottomBar = observer(() => {
  const store = useStore('quiz');
  const { pathname } = useLocation();
  const { t } = useTranslation();

  if (store.state === QuizState.OVERVIEW && pathname.includes('/quiz')) {
    return (
      <Box
        w="full"
        pos="relative"
        pt="16px"
        pb="24px"
        px="20px"
      >
        <Text
          opacity={0.6}
          fontSize="smallMedium"
          lineHeight="150%"
        >
          {t('no-leave-unanswered-questions')}
        </Text>

        <Button
          w="full"
          mt="2"
          isDisabled={store.questionsWithAnswersLength === 0}
          onClick={store.submitAnswers}
        >
          {t('submit-your-answers')}
        </Button>
      </Box>
    );
  }

  return null;
});
