import React from 'react';
import {
  Box, Text,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useStore } from '../store';
import { BlurredImage } from './BlurredImage';
import { Answer } from './Answer';
import { NextPreviousButtons } from './NextPreviousButtons';
import { QuizHeader } from './QuizHeader';

export const QuizQuestionPreview = observer(() => {
  const store = useStore('quiz');
  const question = store.questionsSubmitted[store.currentQuestionIndex];
  const { t } = useTranslation();

  return (
    <>
      <QuizHeader />
      <Text
        color={question.correct ? 'primary.one' : 'tertiary'}
        mb="20px"
        mt="8px"
        ml="37px"
      >
        {`${t('quiz')} ${store.currentQuestionIndex + 1} / ${store.questionsSubmitted.length} – ${question.correct ? t('correct') : t('failed')} `}
      </Text>

      <BlurredImage image={question.image} />

      <Box mt="22px">
        {!question.correct && <Answer isWrong answer={question.yourAnswer} mb="4px" />}
        <Answer answer={question.correctAnswer} mb="15px" />
      </Box>

      <NextPreviousButtons
        goNext={store.nextQuestion}
        goBack={store.previousQuestion}
        shouldDisplayPrevious={store.currentQuestionIndex > 0}
      />
    </>
  );
});
