import {
  Box, Flex, Tag, Text,
} from '@chakra-ui/react';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Quiz } from '../store/quiz/list';
import { ReactComponent as Chevron } from '../icons/chevron-right.svg';

interface Props {
  quiz: Quiz;
  // eslint-disable-next-line react/require-default-props
  taken?: boolean;
}

export const QuizCard: FC<Props> = ({ quiz, taken = false }: Props) => {
  const { t } = useTranslation();

  return (
    <Box
      as={Link}
      to={`/quiz/${quiz.id}`}
      borderColor={taken ? 'lightWithOpacity' : 'tertiary'}
      borderRadius="19px"
      borderWidth="2px"
      p="15px"
      w="full"
      d="block"
      mb="16px"
    >
      <Flex alignItems="center">
        <Box flex={1}>
          <Text fontWeight="bold">{quiz.title}</Text>

          <Flex mt="4px" alignItems="center">
            {quiz.status === 'ready' && (
            <Tag
              bgColor="tertiary"
              p="1px 4px"
              minH="unset"
              fontSize="10px"
              lineHeight="12px"
              fontWeight="bold"
              borderRadius="14px"
              color="white"
              mr="5px"
              textAlign="center"
            >
              {t('new')}
            </Tag>
            )}

            {taken ? <Text fontSize="small" opacity={0.6}>{`${t('taken')} ${quiz.taken}`}</Text>
              : <Text fontSize="small" opacity={0.6}>{`${t('quiz-added')} ${quiz.createdAt}`}</Text>}
          </Flex>
        </Box>

        <Chevron />
      </Flex>
    </Box>
  );
};
