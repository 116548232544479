import { FC } from 'react';
import {
  Box, Text,
} from '@chakra-ui/react';

interface ProfileProps {
  title: string;
  content: string;
}

export const ProfileInfo: FC<ProfileProps> = ({
  title, content,
}: ProfileProps) => (

  <Box mt="17px">
    <Text
      fontSize="smallest"
      lineHeight="120%"
      color="text"
      opacity={0.6}
    >
      {title}
    </Text>
    <Text
      fontWeight="bold"
      fontSize="base"
      color="text"
      lineHeight="120%"
      pt="3px"
    >
      {content}
    </Text>
  </Box>
);
