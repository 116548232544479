import { useState, useEffect } from 'react';

export const useMediaStream = (constraints: MediaStreamConstraints): MediaStream | null => {
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);

  useEffect(() => {
    async function enableStream() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        setMediaStream(stream);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn(err);
      }
    }

    if (!mediaStream || !mediaStream.active) {
      enableStream();
    }

    return () => {
      mediaStream?.getTracks().forEach((track) => {
        track.stop();
      });
    };
  }, [mediaStream, constraints]);

  return mediaStream;
};
