import {
  Box, BoxProps, Button, Flex, Text, Textarea, useDisclosure,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CommentIcon } from '../icons/comment.svg';
import { ReactComponent as Back } from '../icons/back.svg';

interface Props extends Omit<BoxProps, 'onChange'> {
  value: string;
  onChange: (value: string) => void;
}

export const Comment: FC<Props> = ({ value, onChange, ...props }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Box {...props}>
      <Flex alignItems="center" cursor="pointer" onClick={() => onOpen()}>
        <CommentIcon />
        <Text ml={2}>{t('optional-comment')}</Text>
      </Flex>

      <Box
        background="white"
        zIndex={10}
        p={4}
        pos="fixed"
        left={0}
        top={isOpen ? 0 : '100%'}
        h="full"
        w="full"
        transition="top 300ms"
      >
        <Back onClick={() => onClose()}>Close</Back>

        <Textarea
          mt={4}
          placeholder={t('add-comment')}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />

        <Flex mt={2} justifyContent="flex-end">
          <Button onClick={() => onClose()}>{t('add-comment')}</Button>
        </Flex>
      </Box>
    </Box>
  );
};
