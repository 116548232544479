import { makeAutoObservable, runInAction } from 'mobx';
import { apiGet, STORAGE_USER_TOKEN_KEY } from '../../utils/api';

interface User {
  name: string
  avatarUrl: string
}

interface ApiUser {
  canvas_user_id: string
  name: string
  login: string | null
  email: string
  avatar_url: string
  created_at: string
  updated_at: string
}

export class UserStore {
  user: User | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  async getCurrentUser(): Promise<void> {
    try {
      const user: ApiUser = await apiGet('/api/auth/whoami');

      runInAction(() => {
        this.user = {
          name: user.name,
          avatarUrl: user.avatar_url,
        };
      });
    } catch (error) {
      this.logout();
    }
  }

  login = async (oauthToken: string): Promise<void> => {
    try {
      const { token } = await apiGet(`/api/auth/complete?code=${oauthToken}`);
      localStorage.setItem(STORAGE_USER_TOKEN_KEY, token);

      await this.getCurrentUser();
    } catch (error) {
      this.logout();
    }
  };

  logout = (): void => {
    this.user = null;
    localStorage.removeItem(STORAGE_USER_TOKEN_KEY);
  };

  redirectToLoginPage = () => {
    const canvasDomain = process.env.REACT_APP_CANVAS_DOMAIN;
    const clientId = process.env.REACT_APP_CANVAS_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_CANVAS_REDIRECT_URI || `${window.location.origin}/oauth_complete`;

    const url = `https://${canvasDomain}/login/oauth2/auth?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}`;
    this.logout();
    window.location.href = url;
  };
}
