import { makeAutoObservable } from 'mobx';
import { Identification, QuizQuestion, QuizAnsweredQuestion } from '../../types';
import { apiGet, apiPost } from '../../utils/api';

export enum QuizState {
  LOADING = 'loading',
  ANSWERING = 'answering',
  OVERVIEW = 'overview',
  PREVIEW = 'preview',
  PREVIEW_QUESTION = 'previewQuestion',
  ERROR = 'error',
}

export class QuizStore {
  constructor() {
    makeAutoObservable(this);
  }

  quizId = '';

  quizSubmissionId = '';

  quizName = '';

  questions: QuizQuestion[] = [];

  questionsSubmitted: QuizAnsweredQuestion[] = [];

  taken = '';

  currentQuestionIndex = 0;

  state: QuizState = QuizState.LOADING;

  reset = () => {
    this.quizId = '';
    this.quizSubmissionId = '';
    this.quizName = '';
    this.questions = [];
    this.questionsSubmitted = [];
    this.currentQuestionIndex = 0;
    this.state = QuizState.LOADING;
  };

  fetchQuiz = async (quizId: string): Promise<void> => {
    this.quizId = quizId;
    this.questions = [];
    this.state = QuizState.LOADING;

    try {
      const { data } = await apiGet(`/api/quizzes/${quizId}/questions`);
      if (!data.length) {
        this.state = QuizState.ERROR;
        throw new Error('Wrong response');
      }

      this.quizName = data[0].quiz.title;
      const isSubmittedAlready = data[0].quiz.status === 'submitted';

      if (isSubmittedAlready) {
        await this.getSubmittedQuiz(data[0].quiz.submission_uuid);
        return Promise.resolve();
      }

      this.questions = data.map((question: any) => ({
        id: question.uuid,
        image: question.photo,
        answer: null,
      }));
      this.state = QuizState.ANSWERING;
      return Promise.resolve();
    } catch (error) {
      return Promise.reject();
    }
  };

  nextQuestion = (): void => {
    if (this.shouldOpenNextStep) {
      if (this.state === QuizState.ANSWERING) {
        this.state = QuizState.OVERVIEW;
      }
      if (this.state === QuizState.PREVIEW_QUESTION) {
        this.state = QuizState.PREVIEW;
      }
    } else {
      this.currentQuestionIndex += 1;
    }
  };

  previousQuestion = (): void => {
    if (this.allowPrevious) {
      this.currentQuestionIndex -= 1;
    }
  };

  openQuestion = (questionIndex: number): void => {
    if (this.questions[questionIndex]) {
      this.currentQuestionIndex = questionIndex;
      this.state = QuizState.ANSWERING;
    }
  };

  openQuestionPreview = (questionIndex: number): void => {
    this.state = QuizState.PREVIEW_QUESTION;
    this.currentQuestionIndex = questionIndex;
  };

  setAnswer = (answer: Identification | null): void => {
    this.currentQuestion.answer = answer;
  };

  submitAnswers = async (): Promise<void> => {
    const data = this.questions.filter((question: QuizQuestion) => question.answer !== null)
      .map((question:QuizQuestion) => ({
        question_uuid: question.id,
        answer: {
          no: question.answer?.name,
          la: question.answer?.scientificName,
        },
      }));
    try {
      const { uuid } = await apiPost(
        `/api/quizzes/${this.quizId}/submissions`,
        { answers: data },
      );
      await this.getSubmittedQuiz(uuid);
    } catch (error) {
      console.error(error);
    }
  };

  getSubmittedQuiz = async (submissionId: string): Promise<void> => {
    this.quizSubmissionId = submissionId;
    this.state = QuizState.LOADING;
    try {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { data: { answers, created_at } } = await apiGet(`/api/quizzes/${this.quizId}/submissions/${this.quizSubmissionId}`);
      this.taken = created_at;
      this.questionsSubmitted = answers.map((answer: any) => {
        let yourAnswer: any = {};

        if (answer.answer_la || answer.answer_no) {
          yourAnswer.name = answer.answer_no || '';
          yourAnswer.scientificName = answer.answer_la || '';
        } else {
          yourAnswer = null;
        }

        return {
          id: answer.uuid,
          yourAnswer,
          correctAnswer: {
            name: answer.question.names.no,
            scientificName: answer.question.names.la,
          },
          correct: answer.correct,
          image: answer.question.photo,
          createdAt: answer.created_at,
        };
      });
      this.state = QuizState.PREVIEW;
      return Promise.resolve();
    } catch (error) {
      console.error(error);
      return Promise.reject();
    }
  };

  get currentQuestion(): QuizQuestion {
    return this.questions[this.currentQuestionIndex];
  }

  get questionsCount(): number {
    return this.questions.length;
  }

  get submittedQuestionsCount(): number {
    return this.questionsSubmitted.length;
  }

  get shouldOpenNextStep(): boolean {
    if (this.state === QuizState.ANSWERING || this.state === QuizState.OVERVIEW) {
      return this.currentQuestionIndex + 1 === this.questions.length;
    }
    return this.currentQuestionIndex + 1 === this.questionsSubmitted.length;
  }

  get allowPrevious(): boolean {
    return this.currentQuestionIndex > 0;
  }

  get submittedQuestionsWithAnswers(): number {
    return this.questionsSubmitted.filter((question) => !!question.yourAnswer).length;
  }

  get correctAnswered(): number {
    return this.questionsSubmitted.filter((question) => question.correct).length;
  }

  get questionsWithAnswersLength(): number {
    return this.questions.filter((question: QuizQuestion) => question.answer !== null).length;
  }
}
