import { FC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { OAuthHandler } from './components/OAuthHandler';
import { ProtectedRoute } from './components/ProtectedRoute';
import { Dashboard } from './screens/Dashboard';
import { Identify } from './screens/Identify';
import { Login } from './screens/Login';
import { Observe } from './screens/Observe';
import { Profile } from './screens/Profile';
import { Quiz } from './screens/Quiz';
import { QuizList } from './screens/QuizList';

export const Router: FC = () => (
  <Switch>
    <Route path="/login" component={Login} />
    <Route path="/oauth_complete" component={OAuthHandler} />
    <ProtectedRoute path="/capture" component={Observe} />
    <ProtectedRoute path="/identify" component={Identify} />
    <ProtectedRoute path="/quiz/:id" component={Quiz} />
    <ProtectedRoute path="/quiz" component={QuizList} />
    <ProtectedRoute path="/profile" component={Profile} />
    <ProtectedRoute path="/" component={Dashboard} />
    <ProtectedRoute component={Dashboard} />
  </Switch>
);
