import {
  Box, Flex, Text, useTheme,
} from '@chakra-ui/react';
import { FC, SVGProps } from 'react';
import { Link } from 'react-router-dom';
import { Badge } from './Badge';

export interface Route {
  target: string;
  icon: FC<SVGProps<SVGSVGElement>>;
  name: string;
}

interface RouteBoxProps {
  item: Route;
  isActive: boolean;
  badgeText: string;
}

export const RouteBox: FC<RouteBoxProps> = ({ item, isActive, badgeText }: RouteBoxProps) => {
  const { colors } = useTheme();

  return (
    <Flex
      as={Link}
      to={item.target}
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      borderColor="rgba(0,0,0,0.07)"
      borderTopWidth={1}
      borderLeftWidth={1}
      _first={{ borderLeftWidth: 0 }}
      h="60px"
      w="full"
      pos="relative"
    >
      {(badgeText && !isActive) && <Badge text={badgeText} top="-6px" />}
      {isActive && <Box pos="absolute" top="-1px" height="5px" w="full" bgColor={colors.primary.one} />}

      <item.icon fill={isActive ? colors.text : colors.primary.one} />

      {isActive && <Text fontSize="xs" fontWeight="bold" color="text" textTransform="uppercase">{item.name}</Text>}
    </Flex>
  );
};
