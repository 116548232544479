import { Box, Button } from '@chakra-ui/react';
import { FC, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { BlurredImage } from './BlurredImage';
import { SpeciesSearch } from './SpeciesSearch';
import { ReactComponent as Camera } from '../icons/camera.svg';
import { Identification } from '../types';
import { useStore } from '../store';
import { Comment } from './Comment';
import { apiGet } from '../utils/api';
import { CourseIdApiResponse } from '../types/courseId';

interface Props {
  photo: string;
  onClose: () => void;
  uuid: string;
}

export const IdentifyCapturedPhoto: FC<Props> = observer(({ photo, onClose, uuid }: Props) => {
  const [identification, setIdentification] = useState<Identification | null>(null);
  const [comment, setComment] = useState('');
  const { t } = useTranslation();
  const uploadStore = useStore('upload');
  const userStore = useStore('user');

  const {
    addPhoto, removePhotoFromIndexDb, setIsLoading, isLoading,
  } = useStore('upload');
  const { push } = useHistory();

  const upload = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    const courseId = await uploadStore.getCourseId(userStore.redirectToLoginPage);
    if (courseId && identification) {
      removePhotoFromIndexDb(uuid).then(() => {
        setIsLoading(false);
        addPhoto(photo, identification, comment, uuid, courseId);
        push('/');
        // eslint-disable-next-line promise/no-return-wrap
        return Promise.resolve();
      }).catch(() => {
        setIsLoading(false);
        push('/');
        // eslint-disable-next-line promise/no-return-wrap
        return Promise.reject();
      });
    }
  }, [identification,
    removePhotoFromIndexDb,
    uuid,
    setIsLoading,
    addPhoto,
    photo,
    push,
    comment,
    userStore,
    uploadStore]);

  return (
    <Box p={4}>
      <Camera onClick={() => onClose()} opacity={0.6} cursor="pointer" />

      <BlurredImage
        mt={3}
        mb={6}
        w="73px"
        image={photo}
      />

      <SpeciesSearch placeholder={t('what-is-on-this-photo')} value={identification} onChange={setIdentification} />

      <Comment my={4} value={comment} onChange={setComment} />

      {identification && <Button w="full" isLoading={isLoading} onClick={upload}>{t('share')}</Button>}
    </Box>
  );
});
