import {
  Box, Center, SimpleGrid, Text,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IdentifyCard } from '../components/IdentifyCard';
import { QuizCard } from '../components/QuizCard';
import { UserCard } from '../components/UserCard';
import { useStore } from '../store';
import { FirstOpenCard } from '../components/FirstOpenCard';
import { MakeOwnObservationsButton } from '../components/MakeOwnObservationsButton';
import { shouldShowOnFirstOpen } from '../utils/shouldShowOnFirstOpen';

export const Dashboard: FC = observer(() => {
  const store = useStore();
  const { t } = useTranslation();

  useEffect(() => {
    store.quizList.fetch();
    store.upload.init();
  }, [store]);

  return (
    <>
      <UserCard />
      <Box bgColor="white" borderTopRadius="39px" marginTop="-40px" pt="25px" px="20px">

        {!shouldShowOnFirstOpen() && (
          store.quizList.available().length > 0 && (
          <Box
            mb="22px"
          >
            <Text fontWeight="bold" pb="12px">{t('you-have-new-quiz')}</Text>
            <QuizCard quiz={store.quizList.available()[0]} />
          </Box>
          )
        )}

        <FirstOpenCard />

        {!shouldShowOnFirstOpen() && (
          <SimpleGrid my={4} spacing={4} columns={[1, 1, 2]}>
            <MakeOwnObservationsButton />
          </SimpleGrid>
        )}

        <Center><IdentifyCard /></Center>
      </Box>
    </>
  );
});
