import { PropsWithChildren, FC } from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { useTheme } from '@emotion/react';

interface Props {
  percentage: number,
  maxValue?: number
}

export const CircularProgressComponent: FC<Props> = (
  { percentage, children, maxValue = 100 }: PropsWithChildren<Props>,
) => {
  const theme = useTheme();

  return (
    <CircularProgressbarWithChildren
      value={percentage}
      maxValue={maxValue}
      styles={{
        path: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          stroke: theme.colors.tertiary,
          strokeLinecap: 'round',
          transition: 'stroke-dashoffset 0.5s ease 0s',
          strokeWidth: '2px',
        },
        trail: {
          stroke: '#ffffff',
          strokeLinecap: 'round',
          transform: 'rotate(0.25turn)',
          transformOrigin: 'center center',
          strokeWidth: '4px',
        },
      }}
    >
      {children}
    </CircularProgressbarWithChildren>
  );
};
