import { Identification } from './quiz';

export enum PhotoStatus {
  pending = 'Pending',
  uploading = 'Syncing...',
  success = 'Synced successfully!',
  failure = 'Failure',
  paused = 'Paused',
}

export type SerializedPhoto = {
  id: string | null;
  image: string;
  identification: Identification | null;
  description: string;
  status: PhotoStatus;
  localUUID: string;
};

export interface ApiObservation {
  uuid: string;
  type: string;
  photo: string;
  description?: string;
  names: {
    name: string;
    language: string;
  }[];
  owner: {
    uuid: string;
    name: string;
    avatar?: string;
  }
}
