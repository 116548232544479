import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import {
  Text,
  Box,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../store';
import { SpeciesSearch } from './SpeciesSearch';
import { QuizHeader } from './QuizHeader';
import { BlurredImage } from './BlurredImage';
import { NextPreviousButtons } from './NextPreviousButtons';

export const QuizQuestion: FC = observer(() => {
  const store = useStore('quiz');
  const question = store.currentQuestion;
  const { t } = useTranslation();

  return (
    <div>
      <QuizHeader />
      <Text mt="8px" mb="20px">{`${t('question')} ${store.currentQuestionIndex + 1}/${store.questionsCount}`}</Text>

      <BlurredImage
        image={question.image}
        alt={`Question ${question.id}`}
        allowZoom
      />

      <Box mt="30px">
        <SpeciesSearch
          value={question.answer}
          onChange={store.setAnswer}
          placeholder={t('what-is-on-this-photo')}
        />
      </Box>

      <Box mt="25px" mb="16px">
        <NextPreviousButtons
          goNext={store.nextQuestion}
          goBack={store.previousQuestion}
          shouldDisplayPrevious={store.currentQuestionIndex > 0}
        />
      </Box>

      <Text
        opacity={0.6}
        fontSize="smallMedium"
        lineHeight="150%"
        mb="16px"
      >
        {t('review-and-change-questions')}
      </Text>
    </div>
  );
});
