import { Box, Image } from '@chakra-ui/react';
import { FC } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { useHistory } from 'react-router';
import { ReactComponent as CheckWhite } from '../icons/checkWhite.svg';
import { ReactComponent as Resend } from '../icons/resend.svg';
import { CircularProgressComponent } from './CircularProgress';
import { PhotoStatus } from '../types';
import { Badge } from './Badge';
import { useStore } from '../store';

interface Props {
  image: string,
  progress: number,
  id: string | null,
  localUUID: string,
  status: string,
}

export const PhotoWithLoader:FC<Props> = ({
  image, progress, id, localUUID, status,
}: Props) => {
  const history = useHistory();
  const uploadStore = useStore('upload');
  const isFailure = status === PhotoStatus.failure;
  const userStore = useStore('user');

  const redirect = async () => {
    if (!id) {
      if (isFailure) {
        const uploadPhoto = uploadStore.photos.find((photo) => photo.localUUID === localUUID);
        const courseId = await uploadStore.getCourseId(userStore.redirectToLoginPage);
        if (uploadPhoto && courseId) {
          await uploadPhoto.upload(courseId);
        }
      } else {
        history.push('/capture', { localUUID });
      }
    }
  };

  return (
    <Box
      w="73px"
      h="73px"
      mr="10px"
      mb="10px"
      position="relative"
      onClick={redirect}
    >
      {isFailure && <Badge text="!" left="60px" bottom="60px" /> }
      <Image
        src={image}
        fit="cover"
        w="full"
        h="100%"
        pos="absolute"
        filter="blur(4vw) saturate(150%)"
        transform="translateY(4vw)"
        opacity={0.75}
        pointerEvents="none"
        alt={localUUID}
      />

      <Image
        fit="cover"
        src={image}
        borderRadius="2xl"
        pos="absolute"
        w="full"
        h="full"
        filter="brightness(0.5)"
      />

      <Box
        position="absolute"
        width="44px"
        height="44px"
        top="calc(50% - 22px)"
        left="calc(50% - 22px)"
      >
        <CircularProgressComponent percentage={progress}>
          {(progress === 100 && !isFailure) && <CheckWhite />}
          {progress < 100 && isFailure && <Resend />}
        </CircularProgressComponent>
      </Box>

    </Box>
  );
};
