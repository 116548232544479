import { ObservationsStore } from './observations';
import { QuizStore } from './quiz';
import { QuizList } from './quiz/list';
import { UploadStore } from './upload';
import { UserStore } from './user';
import { InputStore } from './input';
import { FullImageStore } from './fullImage';

export const store = {
  user: new UserStore(),
  quiz: new QuizStore(),
  quizList: new QuizList(),
  upload: new UploadStore(),
  observations: new ObservationsStore(),
  input: new InputStore(),
  fullImageStore: new FullImageStore(),
} as const;

export type Store = typeof store;
