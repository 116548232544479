export const captureFrameFromStream = (
  track: MediaStreamTrack,
): Promise<string> => new Promise((resolve, reject) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const imageCapture = new (window as any).ImageCapture(track);

  imageCapture.takePhoto()
    .then((photo: Blob) => {
      const fileReader = new FileReader();

      fileReader.onloadend = () => {
        if (typeof fileReader.result === 'string') resolve(fileReader.result);
        else reject();
      };

      fileReader.readAsDataURL(photo);

      return fileReader;
    })
    .catch((error: Error) => reject(error));
});

export const captureFrameFromPreview = (
  video: HTMLVideoElement,
): Promise<string> => new Promise((resolve, reject) => {
  try {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (context) {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      const data = canvas.toDataURL('image/png');

      resolve(data);
    } else {
      reject();
    }
  } catch (error) {
    reject(error);
  }
});
