import {
  Center, Flex, Image, Spinner, Text, Box,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { useStore } from '../store';
import dashboardBackground from '../images/dashboardBackground.png';
import { CircularProgressComponent } from './CircularProgress';
import { ReactComponent as UploadWhite } from '../icons/uploadWhite.svg';
import { Badge } from './Badge';

export const UserCard = observer(() => {
  const store = useStore('user');
  const uploadStore = useStore('upload');

  const getProgress = () => {
    let progress = 0;
    uploadStore.photos.forEach((photo) => {
      progress += photo.progress;
    });
    return progress;
  };

  if (!store.user) return <Center><Spinner /></Center>;

  return (
    <Flex
      p="15px"
      alignItems="center"
      paddingBottom="60px"
      backgroundImage={`url(${dashboardBackground})`}
      backgroundRepeat="no-repeat"
      backgroundPosition="left 0%"
      backgroundSize="cover"
    >
      {/* TODO: add placeholder */}
      <Link
        to="/profile"
      >
        {!!uploadStore.photos.length && (
        <Box
          w="44px"
          h="44px"
          mr="6px"
          position="relative"
        >
          {!!uploadStore.unnamedPhotosLength && (
            <Badge text={uploadStore.unnamedPhotosLength} left="27px" bottom="29px" />
          )}

          <CircularProgressComponent
            percentage={getProgress()}
            maxValue={uploadStore.photos.length * 100}
          >
            <Box
              position="relative"
              w="38px"
              h="38px"
              overflow="hidden"
              borderRadius="full"
            >
              <Image
                src={store.user.avatarUrl}
                filter="brightness(0.5)"
              />

              <Box
                position="absolute"
                top="calc(50% - 8px)"
                left="calc(50% - 11px)"
              >
                <UploadWhite />
              </Box>
            </Box>

          </CircularProgressComponent>
        </Box>
        )}

        {uploadStore.photos.length === 0 && (
          <Box
            w="40px"
            h="40px"
            borderRadius="full"
            overflow="hidden"
            borderWidth="2px"
            borderColor="white"
            mr="6px"
          >
            <Image
              src={store.user.avatarUrl}
              fit="cover"
              h="100%"
            />
          </Box>

        )}

      </Link>

      <Text flex={1} color="white" fontSize="h3" fontWeight="bold">{store.user.name}</Text>

      <Image w="40px" h="40px" src="https://mitt.uib.no/uib-tilpasninger/img/uib_logo.svg" />
    </Flex>
  );
});
