import { FC } from 'react';
import {
  Box, Text, GridItem, Grid, ChakraProps,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CircleCross } from '../icons/circleCross.svg';
import { ReactComponent as CircleCheck } from '../icons/circleCheck.svg';
import { Identification } from '../types';

interface Props extends ChakraProps {
  // eslint-disable-next-line react/require-default-props
  isWrong?: boolean,
  answer: Identification | null,
}

export const Answer: FC<Props> = ({ isWrong = false, answer, ...props }: Props) => {
  const { t } = useTranslation();

  return (
    <Grid
      templateColumns="repeat(4, 1fr)"
      gap="10px"
      py="10px"
      borderRadius="19px"
      borderWidth={isWrong ? '2px' : '0px'}
      borderColor="tertiary"
      minHeight="64px"
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
    >
      <Box w="100%" h="100%" display="flex" alignItems="center" justifyContent="center">
        {isWrong ? <CircleCross /> : <CircleCheck />}
      </Box>
      <GridItem colSpan={3} display="flex" flexDirection="column" justifyContent="center">
        {answer ? (
          <>
            {answer.name && <Text>{answer.name}</Text>}
            <Text fontStyle="italic">{answer.scientificName}</Text>
          </>
        ) : <Text>{t('no-answer')}</Text>}

      </GridItem>
    </Grid>
  );
};
