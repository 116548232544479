import { createContext, FC, ReactNode } from 'react';
import { store } from './store';

export const StoreContext = createContext(store);

interface StoreProviderProps {
  children: ReactNode;
}

export const StoreProvider: FC<StoreProviderProps> = ({ children }: StoreProviderProps) => (
  <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
);
