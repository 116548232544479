import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { Identification, PhotoStatus, SerializedPhoto } from '../../types';
import { BASE_URL, STORAGE_USER_TOKEN_KEY } from '../../utils/api';
import { UpdatableStore } from './updatableStore';

function mapExtension(type: string) {
  switch (type) {
    case 'image/png':
      return 'png';
    case 'image/x-ms-bmp':
      return 'bmp';
    case 'image/svg+xml':
      return 'svg';
    case 'image/webp':
      return 'webp';
    case 'image/gif':
      return 'gif';
    case 'image/jpeg':
    default:
      return 'jpg';
  }
}

const srcToFile = async (src: string, name: string) => {
  const res = await fetch(src);
  const blob = await res.blob();
  const filename = `${name}${mapExtension(blob.type)}`;
  return new File([blob], filename, { type: blob.type });
};
export class UploadPhoto implements SerializedPhoto {
  constructor(
    public id: string | null,
    public image: string,
    public description: string,
    public identification: Identification | null,
    private parent: UpdatableStore,
    public localUUID: string,
    public status: PhotoStatus,
  ) {
    if (id) {
      this.status = PhotoStatus.success;
    }

    makeAutoObservable(this);

    reaction(() => this.status, async () => {
      await this.parent.updateStorage();
    });
  }

  progress = 0;

  static new(
    image: string,
    description: string,
    identification: Identification | null,
    parent: UpdatableStore,
    localUUID = '',
    status: PhotoStatus = PhotoStatus.paused,
  ): UploadPhoto {
    return new this(null, image, description, identification, parent, localUUID, status);
  }

  static fromJSON(json: SerializedPhoto, parent: UpdatableStore): UploadPhoto {
    return new this(
      json.id,
      json.image,
      json.description,
      json.identification,
      parent,
      json.localUUID,
      json.status,
    );
  }

  // eslint-disable-next-line consistent-return
  upload = async (courseId: string) => {
    if (this.canUpload()) {
      this.status = PhotoStatus.uploading;

      const xhr = new XMLHttpRequest();

      xhr.upload.onprogress = (event: ProgressEvent) => {
        const percentage = (event.loaded * 100) / event.total;
        this.progress = Math.round(percentage);
      };

      xhr.onreadystatechange = (): Promise<void> => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 201) {
            const { uuid } = JSON.parse(xhr.responseText);
            runInAction(() => {
              this.id = uuid;
              this.status = PhotoStatus.success;
            });
            return Promise.resolve();
          }

          runInAction(() => {
            this.status = PhotoStatus.failure;
          });
          return Promise.reject();
        }
        return Promise.resolve();
      };

      const data = new FormData();

      data.append('names[no]', this.identification?.name || '');
      data.append('names[la]', this.identification?.scientificName || '');
      data.append('description', this.description);

      xhr.open('POST', `${BASE_URL}/api/observations`, true);
      xhr.setRequestHeader('Accept', 'application/json');
      xhr.setRequestHeader('Authorization',
        `Bearer ${localStorage.getItem(STORAGE_USER_TOKEN_KEY)}`);
      xhr.setRequestHeader('Canvas-Course-Id', courseId);

      try {
        const file = await srcToFile(this.image, 'photo');
        await data.append('file', file);
      } catch (error) {
        runInAction(() => {
          this.status = PhotoStatus.failure;
        });
        return Promise.reject();
      }

      xhr.send(data);
    } else {
      return Promise.reject();
    }
  };

  canUpload(): boolean {
    return this.status === PhotoStatus.paused || this.status === PhotoStatus.failure;
  }

  setIdentification = (): Identification | null => {
    if (this.identification !== null) {
      return ({
        name: this.identification?.name || '',
        scientificName: this.identification?.scientificName || '',
      });
    }
    return this.identification;
  };

  public toJSON(): SerializedPhoto {
    return {
      id: this.id,
      image: this.image,
      description: this.description,
      identification: this.setIdentification(),
      status: this.status,
      localUUID: this.localUUID,
    };
  }
}
