import { Box, Button } from '@chakra-ui/react';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BlurredImage } from './BlurredImage';
import dashboardImage from '../images/dashboard.png';

export const IdentifyCard: FC = () => {
  const { t } = useTranslation();

  return (
    <BlurredImage
      image={dashboardImage}
      isDashboard
    >
      <Box
        pos="absolute"
        w="100%"
        bottom="0px"
        inset={0}
        borderRadius="2xl"
        background="linear-gradient(180deg, rgba(0, 0, 0, 0) 61.98%, rgba(0, 0, 0, 0.51) 100%)"
      >
        <Box pos="absolute" bottom="0px" p={4} w="full">
          <Button
            as={Link}
            to="/identify"
            variant="outline"
            color="white"
            borderColor="white"
            w="full"
            bg="rgba(0,0,0,.33)"
          >
            {t('identify-others-photos')}
          </Button>
        </Box>
      </Box>
    </BlurredImage>
  );
};
