import {
  Box, Flex, Text, Heading,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Check } from '../icons/check.svg';
import { MakeOwnObservationsButton } from './MakeOwnObservationsButton';
import { shouldShowOnFirstOpen } from '../utils/shouldShowOnFirstOpen';
import { setFirstOpenTime } from '../utils/setFirstOpenTime';

export const FirstOpenCard = () => {
  const { t } = useTranslation();

  useEffect(() => {
    setFirstOpenTime();
  }, []);

  if (!shouldShowOnFirstOpen()) {
    return null;
  }

  const list: string[] = [
    t('take-a-photo'),
    t('name-your-guess'),
    t('share-your-observation'),
  ];

  return (
    <Box
      mb="34px"
    >
      <Heading
        as="h3"
        fontSize="md"
        lineHeight="120%"
      >
        {t('curious-to-lern-new-species')}
      </Heading>

      <Box
        borderRadius="2xl"
        filter="drop-shadow(0px 14px 24px rgba(102, 145, 93, 0.17))"
        borderColor="primary.one"
        borderWidth="2px"
        px="15px"
        py="15px"
        boxSizing="border-box"
        mt="10px"
      >
        <Box
          mb="16px"
        >
          {list.map((item) => (
            <Flex
              key={item}
              alignItems="center"
            >
              <Check />
              <Text
                color="black"
                lineHeight="150%"
                ml="9px"
              >
                {item}
              </Text>

            </Flex>
          ))}
        </Box>

        <MakeOwnObservationsButton />
      </Box>
    </Box>
  );
};
