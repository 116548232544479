import { makeAutoObservable } from 'mobx';
import {
  set, get, update, del,
} from 'idb-keyval';
import { Identification, PhotoStatus, SerializedPhoto } from '../../types';
import { UpdatableStore } from './updatableStore';
import { UploadPhoto } from './uploadPhoto';
import { CourseIdApiResponse } from '../../types/courseId';
import { apiGet } from '../../utils/api';

export const PHOTOS_STORAGE_KEY = 'photos';
export class UploadStore implements UpdatableStore {
  photos: UploadPhoto[] = [];

  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  init = async (): Promise<void> => {
    await this.updateFromStorage();
  };

  addPhoto = async (
    photo: string,
    identification: Identification,
    description: string,
    localUUID: string,
    courseId: string,
  ): Promise<void> => {
    const uploadPhoto = UploadPhoto.new(photo, description, identification, this, localUUID);
    this.photos = [...this.photos, uploadPhoto];

    await uploadPhoto.upload(courseId);
  };

  getPhotoFromIndexDb = async (uuid: string) => {
    try {
      const photos = await get(PHOTOS_STORAGE_KEY);
      if (photos) {
        const searchedPhoto = photos.find((photo: SerializedPhoto) => photo.localUUID === uuid);
        if (searchedPhoto) {
          return Promise.resolve(searchedPhoto);
        }

        throw Error(`Can't find photo with localUUID: ${uuid}`);
      }
      throw Error('Photos list is empty');
    } catch (error) {
      return Promise.reject();
    }
  };

  removePhotoFromStore = (uuid: string) => {
    const updatedPhotos = [...this.photos].filter((photo) => photo.localUUID !== uuid);
    this.photos = [...updatedPhotos];
  };

  setIsLoading = (state: boolean): void => {
    this.isLoading = state;
  };

  removePhotoFromIndexDb = async (uuid: string): Promise<void> => {
    this.setIsLoading(true);
    const photos = await get(PHOTOS_STORAGE_KEY);
    if (photos) {
      this.removePhotoFromStore(uuid);

      if (photos.length - 1 === 0) {
        try {
          await del(PHOTOS_STORAGE_KEY);
          return Promise.resolve();
        } catch (error) {
          return Promise.reject();
        }
      } else {
        try {
          await update(PHOTOS_STORAGE_KEY, (dbPhotos) => {
            const updatedPhotos = [...dbPhotos].filter((photo) => photo.localUUID !== uuid);
            return updatedPhotos;
          });
          return Promise.resolve();
        } catch (error) {
          return Promise.reject();
        }
      }
    }
    return Promise.reject();
  };

  addPhotoToIndexDb = async (photo: string | null, localUUID: string): Promise<void> => {
    if (!photo) {
      return Promise.reject();
    }

    this.isLoading = true;
    const uploadPhoto = UploadPhoto.new(photo, '', null, this, localUUID);
    try {
      const storagePhotos = await get(PHOTOS_STORAGE_KEY);
      if (storagePhotos === undefined) {
        await set(PHOTOS_STORAGE_KEY, [uploadPhoto.toJSON()]);
        this.isLoading = false;
      } else {
        await update(PHOTOS_STORAGE_KEY, (photos) => ([...photos, uploadPhoto.toJSON()]));
        this.isLoading = false;
      }
      return Promise.resolve();
    } catch (error) {
      this.isLoading = false;
      return Promise.reject();
    }
  };

  async updateFromStorage(): Promise<void> {
    try {
      const storagePhotos = await get(PHOTOS_STORAGE_KEY) as SerializedPhoto[];
      if (storagePhotos) {
        const uniquePhotos = storagePhotos
          .filter((photo) => {
            const unique = this.photos.find((p) => photo.localUUID === p.localUUID);
            if (unique) {
              return false;
            }
            return true;
          })
          .map((photo) => UploadPhoto.fromJSON(photo, this));
        this.photos = [
          ...this.photos,
          ...uniquePhotos,
        ];
      }
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  get unnamedPhotosLength(): number {
    return this.photos.filter(
      (photo) => photo.identification === null,
    ).length;
  }

  async updateStorage(): Promise<void> {
    try {
      await update(PHOTOS_STORAGE_KEY, () => this.photos
        .filter((photo) => (photo.status !== PhotoStatus.success || photo.identification === null))
        .map((photo: UploadPhoto) => photo.toJSON()));
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  getCourseId = async (onTokenExpired: () => any):Promise<any> => {
    try {
      const data:CourseIdApiResponse[] = await apiGet('/api/courses');
      return data[0].id.toString();
    } catch (error) {
      if (error.redirect_url) {
        onTokenExpired();
      }
      return Promise.reject();
    }
  };
}
