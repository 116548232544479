import {
  Text, Box, Center, Flex, Button,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InfoIcon } from '../icons/info.svg';

export const NoMoreIdentificationsAvailable = () => {
  const { t } = useTranslation();

  return (
    <Box w="full" h="full">
      <Box
        maxW="600px"
        maxH="600px"
        borderRadius="2xl"
        borderColor="rgba(45, 59, 69, 0.33)"
        borderWidth="1px"
      >
        <Center w="full" h="320px">
          <Flex>
            <InfoIcon />
            <Text ml="8px" fontSize="smallMedium">{t('no-more-photos-to-identify')}</Text>
          </Flex>
        </Center>
      </Box>

      <Button as={Link} w="full" to="/capture" my="16px">{t('make-an-observation')}</Button>
    </Box>
  );
};
